import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                close: 'close',
                toggleSub: 'toggleSub',
            }
        }
    }

    init() {

    }

    close() {
        const html = document.querySelector('html');
        const burger = document.querySelector('.c-header_nav_burger');
        const text = burger.querySelector('.-hover');
        html.classList.remove('has-navOpen');
        burger.classList.remove('is-active');
        text.textContent = text.dataset.texthover;
    }

    toggleSub(e) {
        let target = e.currentTarget;
        let container = $(target).parents('.c-nav_primary_wrap').toggleClass('is-active');
    }
}
