import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                trigger: 'trigger',
            },
            change: {
                option: 'option',
            }
        }
    }

    init() {

    }

    trigger() {
        this.el.classList.toggle('is-active');
    }

    option(e) {
        let target = e.currentTarget;
        let text = $(target).data('name');
        $(this.el).find('.c-dropdown_label').text(text);
        this.el.classList.remove('is-active');
    }
}
