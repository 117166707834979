import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
              show: 'show',
            }
          }
    }

    init() {

    }

    show(e) {
        let target = e.currentTarget;
        let id = target.getAttribute('data-id');
        $(this.el).find('.b-relation_dossiers_content_wrap').removeClass('is-active');
        $(this.el).find('.b-relation_dossiers_nav_cat-item').removeClass('is-active');
        $(this.el).find('.b-relation_dossiers_content').removeClass('is-active');
        $('[data-id="'+id+'"]').parents('.b-relation_dossiers_content_wrap').addClass('is-active');
        $('[data-id="'+id+'"]').addClass('is-active');

        this.call('update', false, 'Scroll'); // update Locomotive Scroll
    }
}
