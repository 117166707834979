import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
        //   click: {
        //     header: 'toggleSection',
        //   }
            change: {
                change: 'refresh',
            }
        }

        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
    }

    init() {
        setTimeout(() => {
            let form = $('#etablissementFiltresForm');
            let $this = this;
              
              jQuery.ajax({
                  url: this.ajaxurl,
                  type: 'POST',
                  data: form.serialize(),
                  success: function( response ){
                      if($('.b-relation_listing').length) {
                          $('.b-relation_listing').html(response.content);
                      }
                      if($('.b-relation_number').length && response.count) {
                          $('.b-relation_number').html(response.count);
                      }
                      if(response.lieux) {
                          $this.call('updateMap', response.lieux, 'Map');
                      }
                  },
              });
              
              setTimeout(() => {
                  this.call('update', false, 'Scroll'); // update Locomotive Scroll
              }, 500);
        }, 2500);
    }

    refresh(e) {
        const target = e.currentTarget;
        let form = jQuery(target).parents('form');
        let $this = this;
        
        jQuery.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: form.serialize(),
            success: function( response ){
                
                if($('.b-relation_listing').length) {
                    $('.b-relation_listing').html(response.content);
                }
                if($('.b-relation_number').length && response.count) {
                    $('.b-relation_number').html(response.count);
                }
                if(response.lieux) {
                    $this.call('updateMap', response.lieux, 'Map');
                }
            },
        });
        
        setTimeout(() => {
            this.call('update', false, 'Scroll'); // update Locomotive Scroll
        }, 500);
    }

}
